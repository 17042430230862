import React from "react";
import classes from "./ConfirmationModal.module.css";
import { IoIosWarning } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const ConfirmationModal = ({subscribeLoading,confirmationModal,closeConfirmationModal,getSubData}) => {

  return (
    <div className={`${confirmationModal ? `${classes.modal_container} ${classes.modal_show}` : classes.modal_container}`}>
      <div className={classes.modal_sub_container}>
        <div className={classes.icon}>
          {/* <IoIosWarning className={classes.icon_style}/> */}
          {/* <div className={classes.logo}> */}
            <img src="/assets/security.png" alt="confirm-icon" />
          {/* </div> */}
        </div>
        <div className={classes.modal_content}>
            {/* <div className={classes.main_heading}>
                <p>You are not subscriber</p>
            </div> */}
            <div className={classes.paragraph}>
                <p>Confirm your subscription to BigCash?</p>
            </div>
        </div>
        <form className={classes.btn_container} onSubmit={getSubData} >
            <button className={classes.confirm_btn} 
              id="cta_button"
            type="submit"
            >
                 {subscribeLoading ? (
                  <div style={{width:"100%",display:"flex",justifyContent:'center'}}>

                    <div className={classes.loader}></div>
                  </div>
                    ) : (
                      "Yes"
                    )}
            </button>
            <button 
            type="button"
            className={classes.cancel_btn} onClick={closeConfirmationModal}>
                No
            </button>
        </form>
      </div>
    </div>
  );
};

export default ConfirmationModal;
